import React from 'react';
import { Navigate } from 'react-router-dom';


const ProtectedRoute = ({ children, role }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const isParent = localStorage.getItem('isParent') === 'true';


  // Check if user is not logged in
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // Check if the route requires parent role but user is not a parent
  if (role === 'parent' && !isParent) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
