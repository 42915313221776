// ThemeContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

// Define themes with AppBar overrides to ensure background consistency
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#90caf9' },
    background: { default: '#212121', paper: '#424242' },
    text: { primary: '#ffffff' },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: { backgroundColor: '#212121' }, // Ensure AppBar uses the same dark mode background
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#1976d2' },
    background: { default: '#f0f0f0', paper: '#ffffff' },
    text: { primary: '#000000' },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: { backgroundColor: '#f0f0f0' }, // Ensure AppBar uses the same light mode background
      },
    },
  },
});

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Initialize theme based on saved preference in localStorage
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  useEffect(() => {
    // Save theme preference to localStorage whenever it changes
    localStorage.setItem('theme', JSON.stringify(isDarkTheme));
  }, [isDarkTheme]);

  const toggleTheme = () => setIsDarkTheme(!isDarkTheme); // Toggle between dark and light theme

  const theme = isDarkTheme ? darkTheme : lightTheme; // Use the correct theme based on the dark mode state

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ isDarkTheme, toggleTheme, theme }}>
        {children}
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
};

// Custom hook to use the theme context values
export const useTheme = () => useContext(ThemeContext);
