import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';

// Import Emotion Cache
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Retrieve the nonce from the CSP
const nonce = document.querySelector('meta[name="csp-nonce"]')?.content || '';

// Create an Emotion cache with the nonce
const cache = createCache({
  key: 'css',
  nonce: nonce,
});

// Define route to title mapping
const routeTitleMap = {
  '/': 'Home - FunAITutor',
  '/login': 'Login - FunAITutor',
  '/register': 'Register - FunAITutor',
  '/change-password': 'Change Password - FunAITutor',
  '/forgot-password': 'Forgot Password - FunAITutor',
  '/verify-email': 'Verify Email - FunAITutor',
  '/parent-dashboard': 'Parent Dashboard - FunAITutor',
  '/chatbot': 'Chatbot - FunAITutor',
};

// Function to track page views using GTM
const trackPageViewWithGTM = (path, title) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      page_path: path,
      page_title: title,
    });
  }
};

// Component to track page views and update titles on route changes
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const title = routeTitleMap[location.pathname] || 'FunAITutor'; // Default title if route is not mapped
    document.title = title; // Update the browser title
    trackPageViewWithGTM(location.pathname, title); // Track the page view with GTM
  }, [location]);

  return null;
};

// Root rendering logic
const root = document.getElementById('root');
const appRoot = createRoot(root);

appRoot.render(
  <React.StrictMode>
    <CacheProvider value={cache}> {/* Provide the Emotion cache */}
      <BrowserRouter>
        <ThemeProvider>
          <AuthProvider>
            <TrackPageView />
            <App />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </CacheProvider>
  </React.StrictMode>
);

reportWebVitals(console.log);
