import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {
  Container, TextField, Button, Checkbox, FormControlLabel, CssBaseline, Typography, Box, MenuItem
} from '@mui/material';
import { useTheme } from '../context/ThemeContext';
import * as yup from 'yup';
import Modal from '../components/Modal'; // Import Modal component

// Define the basic validation schema
const schema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().min(4, 'Password must be at least 4 characters long').required('Password is required'),
  name: yup.string().required('Parent name is required'),
  isParent: yup.boolean().default(false)
});

const RegisterPage = () => {
  const navigate = useNavigate();
  const { isDarkTheme } = useTheme();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const isParent = watch('isParent');

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
  };

  const decryptData = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const onSubmit = async (data) => {
    if (data.isParent) {
      if (!data.childName) {
        setModalTitle("Error");
        setModalMessage('Child Name is required');
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
        return;
      }
      if (!data.childAge) {
        setModalTitle("Error");
        setModalMessage('Child Age is required');
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
        return;
      }
      if (!data.childUsername) {
        setModalTitle("Error");
        setModalMessage('Child Username is required');
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
        return;
      }
      if (!data.childPassword || data.childPassword.length < 4) {
        setModalTitle("Error");
        setModalMessage('Child Password must be at least 4 characters long');
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
        return;
      }
    }

    try {
      // Normalise the email and usernames to lowercase
      const normalisedEmail = data.email.trim().toLowerCase();
      const normalisedChildUsername = data.childUsername ? data.childUsername.trim().toLowerCase() : null;
  
      const { isParent, childPassword, childName, childAge, ...userDetails } = data;
  
      const payload = isParent
        ? { ...userDetails, email: normalisedEmail, isParent, childUsername: normalisedChildUsername, childPassword, childName, childAge }
        : { ...userDetails, email: normalisedEmail, isParent: false };
  
      const encryptedPayload = encryptData(payload);
      const response = await axios.post(`https://www.funaitutor.co.uk/users/register`, { payload: encryptedPayload });
  
      if (response.data && response.data.reply) {
        const decryptedResponse = decryptData(response.data.reply);
        setModalTitle("Success");
        setModalMessage(decryptedResponse.message);
        setModalAction(() => () => navigate('/login'));
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
          navigate('/login');
        }, 10000);
        setTimeoutId(id);
      } else {
        setModalTitle("Error");
        setModalMessage('Registration failed. No response from server.');
        setModalAction(null);
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
        }, 2000);
        setTimeoutId(id);
      }
    } catch (error) {
      setModalTitle("Error");
      setModalMessage('Registration failed. Please try again!');
      setModalAction(null);
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
      if (process.env.NODE_ENV !== 'production') {
        console.error('Registration error:', error.response?.data || error.message);
      }
    }
  };

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
    if (modalAction) {
      modalAction();
    }
  };

  const ages = Array.from({ length: 18 }, (_, i) => i + 1);

  return (
    <Container component="main" maxWidth="xs" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', pt: { xs: 8, sm: 10 }, pb: 8 }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3, borderRadius: 1, boxShadow: 3, backgroundColor: isDarkTheme ? 'background.defaultblack' : 'background.default', overflow: 'auto' }}>
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', color: isDarkTheme ? 'white' : 'inherit' }}>Register</Typography>
        <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            {...register("name")}
            label="Parent Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            sx={textFieldStyle(isDarkTheme)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            {...register("email")}
            label="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={textFieldStyle(isDarkTheme)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            {...register("password")}
            label="Password"
            type="password"
            error={!!errors.password}
            helperText={errors.password?.message}
            sx={textFieldStyle(isDarkTheme)}
          />
          <FormControlLabel
            control={<Checkbox {...register("isParent")} />}
            label="Add a child account"
          />
          {isParent && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                {...register("childName")}
                label="Child Name"
                error={!!errors.childName}
                helperText={errors.childName?.message}
                sx={textFieldStyle(isDarkTheme)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                {...register("childAge")}
                label="Child Age"
                select
                error={!!errors.childAge}
                helperText={errors.childAge?.message}
                sx={textFieldStyle(isDarkTheme)}
              >
                {ages.map(age => (
                  <MenuItem key={age} value={age}>
                    {age}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="normal"
                required
                fullWidth
                {...register("childUsername")}
                label="Child Username"
                error={!!errors.childUsername}
                helperText={errors.childUsername?.message}
                sx={textFieldStyle(isDarkTheme)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                {...register("childPassword")}
                label="Child Password"
                type="password"
                error={!!errors.childPassword}
                helperText={errors.childPassword?.message}
                sx={textFieldStyle(isDarkTheme)}
              />
            </>
          )}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: isDarkTheme ? 'primary.main' : 'primary.main' }}>
            Register
          </Button>
        </form>
      </Box>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </Container>
  );
};

// Helper function to apply consistent styles
function textFieldStyle(isDarkTheme) {
  return {
    '& .MuiInputBase-input': { color: isDarkTheme ? 'white' : 'black' },
    '& label.Mui-focused': { color: isDarkTheme ? 'white' : 'black' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&:hover fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&.Mui-focused fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
    },
  };
}

export default RegisterPage;
