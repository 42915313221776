import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Modal from '../components/Modal'; // Import Modal component
import { Container, Box, Typography, CircularProgress, CssBaseline } from '@mui/material';

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    axios.get(`https://www.funaitutor.co.uk/users/verify-email?token=${token}`)
      .then((response) => {
        const successMessage = response.data.message;
        setModalTitle("Success");
        setModalMessage(successMessage);
        setModalAction(() => () => navigate('/login', { state: { message: successMessage } }));
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
          navigate('/login', { state: { message: successMessage } });
        }, 5000);
        setTimeoutId(id);
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || 'Failed to verify email.';
        setModalTitle("Error");
        setModalMessage(errorMessage);
        setModalAction(() => () => navigate('/login', { state: { message: errorMessage } }));
        setModalShow(true);
        const id = setTimeout(() => {
          setModalShow(false);
          navigate('/login', { state: { message: errorMessage } });
        }, 2000);
        setTimeoutId(id);
      });
  }, [token, navigate]);

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
    if (modalAction) {
      modalAction();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            minHeight: '100vh', 
            pt: { xs: 8, sm: 10 }, // Added padding to prevent overlap with Navbar
            textAlign: 'center' 
          }}
        >
          <Typography variant="h4" sx={{ mb: 4 }}>
            Verifying your email...
          </Typography>
          <CircularProgress />
        </Box>
      </Container>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </React.Fragment>
  );
};

export default VerifyEmailPage;
