import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, TextField, IconButton, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import { ChatContext } from '../context/ChatContext';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext'; // Import useAuth to access isVerifying
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CryptoJS from 'crypto-js';

function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
}

const ChatHistory = ({ setIsChatHistoryOpen }) => {
  const { isDarkTheme } = useTheme();
  const { isVerifying } = useAuth(); // Access isVerifying from AuthContext
  const {
    conversations,
    activeConversation,
    renameConversation,
    clearChat,
    setCurrentConversationId,
    setCurrentConversationHistory,
    setActiveConversation,
    deleteConversation
  } = useContext(ChatContext);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [currentEditingId, setCurrentEditingId] = useState(null);
  const [newTitle, setNewTitle] = useState('');

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Conditional rendering based on isVerifying
  if (isVerifying) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  const handleStartNewChatConfirmation = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDialogClose = async (confirmed) => {
    setConfirmOpen(false);
    if (confirmed) {
      await clearChat();
      setIsChatHistoryOpen(false);  // Hide chat history after starting a new chat
    }
  };

  const handleChatHistoryItemClick = (conversationId) => {
    const selectedConversation = conversations.find(conv => conv._id === conversationId);
    if (selectedConversation) {
      setCurrentConversationId(conversationId);
      setCurrentConversationHistory(selectedConversation.messages);
      setActiveConversation(selectedConversation);
      localStorage.setItem('activeConversationId', encryptData(conversationId)); // Encrypting activeConversationId

      setIsChatHistoryOpen(false); // Close the chat history when a conversation is selected
    } else {
      console.error("Selected conversation not found:", conversationId);
    }
  };

  const openRenameDialog = (conversationId) => {
    setCurrentEditingId(conversationId);
    const currentTitle = conversations.find(conv => conv._id === conversationId).title;
    setNewTitle(currentTitle);
    setRenameDialogOpen(true);
  };

  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false);
  };

  const handleSaveTitle = () => {
    if (newTitle.trim() && currentEditingId) {
      renameConversation(currentEditingId, newTitle.trim());
      handleRenameDialogClose();
    }
  };

  const handleDeleteClick = (conversationId) => {
    setConversationToDelete(conversationId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = (confirmed) => {
    if (confirmed) {
      const isActive = activeConversation && conversationToDelete === activeConversation._id;
      deleteConversation(conversationToDelete);
      if (isActive) {
        const remainingConversations = conversations.filter(conv => conv._id !== conversationToDelete);
        if (remainingConversations.length > 0) {
          const newActiveConversation = remainingConversations[0];
          setCurrentConversationId(newActiveConversation._id);
          setCurrentConversationHistory(newActiveConversation.messages);
          setActiveConversation(newActiveConversation);
          localStorage.setItem('activeConversationId', encryptData(newActiveConversation._id)); // Encrypting activeConversationId
        } else {
          setCurrentConversationId(null);
          setCurrentConversationHistory([]);
          setActiveConversation(null);
          localStorage.removeItem('activeConversationId');
        }
      }
    }
    setDeleteConfirmOpen(false);
    setConversationToDelete(null);
  };

  const chatHistoryHeaderBgColor = isDarkTheme ? '#1E1E1E' : '#F5F5F5';
  const chatHistoryHeaderTextColor = isDarkTheme ? '#FFFFFF' : '#333333';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          background: chatHistoryHeaderBgColor,
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1001,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography variant="h6" sx={{ color: chatHistoryHeaderTextColor, margin: 0 }}>
          Chat History
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: '5px 10px',
            fontSize: '0.8rem',
            textTransform: 'none',
            backgroundColor: isDarkTheme ? '#555' : '#007bff',
            color: isDarkTheme ? 'white' : 'white',
            '&:hover': {
              backgroundColor: isDarkTheme ? '#666' : '#0056b3'
            }
          }}
          onClick={handleStartNewChatConfirmation}
        >
          Start New Chat
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px' }}>
        <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
          {conversations.map((conversation) => (
            <li
              key={conversation._id}
              className={`chat-history-item ${activeConversation && conversation._id === activeConversation._id ? 'active' : ''}`}
              onClick={() => handleChatHistoryItemClick(conversation._id)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px 10px',
                borderBottom: '1px solid #E0E0E0',
                cursor: 'pointer',
                backgroundColor: activeConversation && conversation._id === activeConversation._id
                  ? (isDarkTheme ? '#333333' : '#E7E7E7')
                  : (isDarkTheme ? '#1E1E1E' : '#fff'),
                color: isDarkTheme ? '#fff' : '#000',
              }}
            >
              <span style={{
                flexGrow: 1,
                whiteSpace: 'normal', // Allow wrapping
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: '10px',
                maxWidth: '160px',
                display: '-webkit-box',
                WebkitLineClamp: 2, // Limit to 2 lines
                WebkitBoxOrient: 'vertical'
              }}>
                {conversation.title}
              </span>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <IconButton
                  aria-label="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    openRenameDialog(conversation._id);
                  }}
                  className={`small-icon ${isDarkTheme ? 'dark-mode-icon' : ''}`}
                  title="Rename Chat"
                  size="small"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(conversation._id);
                  }}
                  className={`small-icon ${isDarkTheme ? 'dark-mode-icon' : ''}`}
                  title="Delete Chat"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </li>
          ))}
        </ul>
      </Box>

      {/* Start New Chat Confirmation Dialog */}
      <Dialog 
        open={confirmOpen} 
        onClose={() => handleConfirmDialogClose(false)}
        fullWidth
        maxWidth="sm"  // Limit the maximum width to "sm"
        fullScreen={isSmallScreen} // If on small screen, take up the full screen
        sx={{
          margin: isSmallScreen ? '20px 0 20px auto' : 'auto',
          height: isSmallScreen ? 'calc(100% - 40px)' : 'auto',
          width: '60%',
          left: 0, // Position the dialog at the left side of the screen
          transform: 'none', // Ensure the dialog is not centered by default
          right: 'auto', // Remove any right alignment that might interfere
        }}
      >
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <DialogTitle>Start a New Chat?</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => handleConfirmDialogClose(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleConfirmDialogClose(true)} color="primary">
                Start New Chat
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={deleteConfirmOpen} 
        onClose={() => handleDeleteConfirm(false)}
        fullWidth
        maxWidth="sm"  // Limit the maximum width to "sm"
        fullScreen={isSmallScreen} // If on small screen, take up the full screen
        sx={{
          margin: isSmallScreen ? '20px 0 20px auto' : 'auto',
          height: isSmallScreen ? 'calc(100% - 40px)' : 'auto',
          width: '60%',
          left: 0, // Position the dialog at the left side of the screen
          transform: 'none', // Ensure the dialog is not centered by default
          right: 'auto', // Remove any right alignment that might interfere
        }}
      >
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <DialogTitle>Are you sure you want to delete this conversation?</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => handleDeleteConfirm(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleDeleteConfirm(true)} color="primary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

      {/* Rename Conversation Dialog */}
      <Dialog 
        open={renameDialogOpen} 
        onClose={handleRenameDialogClose}
        fullWidth
        maxWidth="sm"  // Limit the maximum width to "sm"
        fullScreen={isSmallScreen} // If on small screen, take up the full screen
        sx={{
          margin: isSmallScreen ? '20px 0 20px auto' : 'auto',
          height: isSmallScreen ? 'calc(100% - 40px)' : 'auto',
          width: '60%',
          left: 0, // Position the dialog at the left side of the screen
          transform: 'none', // Ensure the dialog is not centered by default
          right: 'auto', // Remove any right alignment that might interfere
        }}
      >
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <DialogTitle>Rename Conversation</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              label="New Title"
              type="text"
              fullWidth
              variant="outlined"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={handleRenameDialogClose}>Cancel</Button>
              <Button onClick={handleSaveTitle}>Save</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

export default ChatHistory;
