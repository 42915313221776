import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Modal from '../components/Modal'; // Import Modal component

const ForgotPasswordPage = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const navigate = useNavigate();

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({ emailOrUsername });
      await axios.post('https://www.funaitutor.co.uk/users/forgot-password', { payload });
      const isEmail = emailOrUsername.includes('@');
      const accountType = isEmail ? 'parent' : 'child';
      setModalTitle("Success");
      setModalMessage(`Password reset code sent to the email associated with ${accountType} account: ${emailOrUsername}.`);
      setModalShow(true);
      setCodeSent(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 5000);
      setTimeoutId(id);
    } catch (error) {
      console.error('Error sending email', error);
      setModalTitle("Error");
      setModalMessage("Error sending email");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const codePayload = encryptData({ emailOrUsername, code });
      await axios.post('https://www.funaitutor.co.uk/users/verify-reset-code', { payload: codePayload });
      setModalTitle("Success");
      setModalMessage("Code verified. Redirecting to change password page.");
      setModalAction(() => () => navigate('/change-password', { state: { emailOrUsername, code } }));
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
        navigate('/change-password', { state: { emailOrUsername, code } });
      }, 3000);
      setTimeoutId(id);
    } catch (error) {
      console.error('Error verifying code', error);
      setModalTitle("Error");
      setModalMessage("Error verifying code");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
    }
  };

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
    if (modalAction) {
      modalAction();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          {!codeSent ? (
            <form onSubmit={handleEmailSubmit} style={{ width: '100%', marginTop: '1em' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="emailOrUsername"
                label="Email Address or Username"
                name="emailOrUsername"
                autoComplete="emailOrUsername"
                autoFocus
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: '1em 0' }}
              >
                Send Reset Code
              </Button>
            </form>
          ) : (
            <form onSubmit={handleCodeSubmit} style={{ width: '100%', marginTop: '1em' }}>
              <Typography variant="h6">
                Reset code sent to the email associated with the account: {emailOrUsername}.
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Enter Code"
                name="code"
                autoComplete="code"
                autoFocus
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: '1em 0' }}
              >
                Verify Code
              </Button>
            </form>
          )}
        </Box>
      </Container>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
