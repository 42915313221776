import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal'; // Correct import path for Modal

// Encryption function
function encryptData(data) {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
}

// Decryption function
function decryptData(data) {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [isVerifying, setIsVerifying] = useState(true); // New state to track verification
  const navigate = useNavigate();

  // Logout function
  const logout = useCallback(
    async () => {
      try {
        await axios.post(
          'https://www.funaitutor.co.uk/users/logout',
          {},
          { withCredentials: true }
        );
        const theme = localStorage.getItem('theme');
        const keysToRemove = [
          'isLoggedIn',
          'isParent',
          'conversations',
          'currentConversationId',
          'currentConversationHistory',
          'activeConversationId',
        ];

        keysToRemove.forEach((key) => {
          if (localStorage.getItem(key) !== null) {
            localStorage.removeItem(key);
          }
        });

        if (theme) {
          localStorage.setItem('theme', theme);
        }

        setUser(null);
        setIsModalOpen(false); // Close the modal if it's open
        navigate('/login');
      } catch (error) {
        console.error('Logout failed:', error);
        throw error;
      }
    },
    [navigate]
  );

  // Function to close the modal and logout
  const closeModalAndLogout = useCallback(() => {
    setIsModalOpen(false); // Close the modal
    logout(); // Proceed with logout
  }, [logout]);

  const handleSessionExpired = useCallback(
    () => {
      setIsModalOpen(true); // Show the modal
      setTimeout(() => {
        closeModalAndLogout(); // Close the modal and log the user out after 5 seconds
      }, 5000);
    },
    [closeModalAndLogout]
  );

  const verifyUser = useCallback(
    async () => {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      if (isLoggedIn) {
        try {
          const response = await axios.get(
            'https://www.funaitutor.co.uk/users/verifySession',
            { withCredentials: true }
          );
          if (response.data && response.data.reply) {
            const decryptedResponse = decryptData(response.data.reply);
            if (decryptedResponse.user) {
              const processedUser = processUser(decryptedResponse.user);
              setUser(processedUser);
              localStorage.setItem('isParent', processedUser.isParent);
            } else {
              throw new Error('No user data');
            }
          }
        } catch (error) {
          console.error('Authentication error:', error);
          handleSessionExpired(); // Trigger the modal when authentication error occurs
        } finally {
          setIsVerifying(false); // Set loading to false after verification
        }
      } else {
        setUser(null);
        localStorage.removeItem('isParent');
        setIsVerifying(false); // Set loading to false when user is not logged in
      }
    },
    [handleSessionExpired]
  );

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  const processUser = (userData) => {
    return {
      ...userData,
      isParent: userData.isParent,
      isChild: !userData.isParent,
    };
  };

  const login = async (login, password) => {
    try {
      const payload = encryptData({ login, password });
      const response = await axios.post(
        'https://www.funaitutor.co.uk/users/login',
        { payload },
        { withCredentials: true }
      );

      if (response.data && response.data.reply) {
        const decryptedResponse = decryptData(response.data.reply);
        if (decryptedResponse.user) {
          const processedUser = processUser(decryptedResponse.user);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('isParent', processedUser.isParent);
          setUser(processedUser);
        } else {
          throw new Error('Login failed: No user data returned');
        }
      } else {
        throw new Error('Login failed: No reply data');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.reply
      ) {
        const decryptedResponse = decryptData(error.response.data.reply);
        if (decryptedResponse.clearLocalStorage) {
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('isParent');
        }
      }
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, login, logout, isVerifying, handleSessionExpired }}
    >
      {children}
      <Modal
        show={isModalOpen}
        handleClose={closeModalAndLogout} // Use closeModalAndLogout here
        title="Session Expired"
        message="Your session has expired. Please log in again."
        onConfirm={closeModalAndLogout} // Use closeModalAndLogout here
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthProvider };
