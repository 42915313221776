import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '../context/ThemeContext';

const Modal = ({ show, handleClose, title, message, onConfirm }) => {
  const { theme } = useTheme();

  if (!show) {
    return null;
  }

  const handleButtonClick = () => {
    handleClose();
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        zIndex: 1000,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={handleClose}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
          maxWidth: '500px',
          width: '80%',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Typography variant="h6" color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Typography variant="body1" color={theme.palette.text.primary} sx={{ my: 2 }}>
          {message}
        </Typography>
        <Button variant="contained" onClick={handleButtonClick}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default Modal;
