import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user, logout } = useAuth(); // Get user and logout function from AuthContext
    const { isDarkTheme, toggleTheme, theme } = useTheme();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerContent = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button component={RouterLink} to="/">
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component={RouterLink} to="/chatbot">
                    <ListItemIcon><ChatIcon /></ListItemIcon>
                    <ListItemText primary="Chatbot" />
                </ListItem>
                {user && user.isParent && (
                    <ListItem button component={RouterLink} to="/parent-dashboard">
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Parent Dashboard" />
                    </ListItem>
                )}
            </List>
            <Divider />
            {user ? (
                <List>
                    <ListItem button onClick={logout}>
                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            ) : (
                <List>
                    <ListItem button component={RouterLink} to="/login">
                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                        <ListItemText primary="Login" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/register">
                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                        <ListItemText primary="Register" />
                    </ListItem>
                </List>
            )}
        </Box>
    );

    return (
        <AppBar position="fixed" color="default">
            <Toolbar sx={{ justifyContent: 'space-between', padding: '0 20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 1, display: { xs: 'block', sm: 'none' } }}
                        onClick={toggleDrawer(true)}
                    >
                        <Box
                            sx={{
                                padding: '4px 12px',
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '4px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="button" sx={{ fontSize: '1rem' }}>
                                Menu
                            </Typography>
                        </Box>
                    </IconButton>
                    <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                    >
                        {drawerContent}
                    </Drawer>
                    <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                        FunAI Tutor
                    </Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1 }}>
                    <Button color="inherit" component={RouterLink} to="/">Home</Button>
                    <Button color="inherit" component={RouterLink} to="/chatbot">Chatbot</Button>
                    {user && user.isParent && (
                        <Button color="inherit" component={RouterLink} to="/parent-dashboard">Parent Dashboard</Button>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton onClick={toggleTheme} sx={{ color: theme.palette.text.primary }}>
                        {isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                    {user ? (
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', gap: 1 }}>
                            <AccountCircleIcon sx={{ color: theme.palette.text.primary }} />
                            <Typography variant="subtitle1" noWrap sx={{ color: theme.palette.text.primary }}>
                                {user.username}
                            </Typography>
                            <Button variant="outlined" color="inherit" onClick={logout} sx={{ color: theme.palette.text.primary }}>
                                Logout
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1 }}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                component={RouterLink} to="/login"
                                sx={{
                                    color: theme.palette.text.primary,
                                    borderColor: theme.palette.text.primary,
                                    '&:hover': {
                                        borderColor: theme.palette.text.primary,
                                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                    },
                                }}
                            >
                                Login
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                component={RouterLink} to="/register"
                                sx={{
                                    color: isDarkTheme ? 'black' : 'white',
                                    backgroundColor: theme.palette.secondary.main,
                                    '&:hover': {
                                        backgroundColor: theme.palette.secondary.dark,
                                    },
                                }}
                            >
                                Register
                            </Button>
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
