import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, CssBaseline } from '@mui/material';
import CryptoJS from 'crypto-js';
import Modal from '../components/Modal'; // Import Modal component

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { emailOrUsername, code } = location.state || {};
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailOrUsername || !code) {
      setModalTitle("Error");
      setModalMessage("Invalid request. Please request a new password reset.");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
      return;
    }

    if (password !== confirmPassword) {
      setModalTitle("Error");
      setModalMessage("Passwords do not match");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
      return;
    }

    try {
      const payload = encryptData({ token: code, password });
      await axios.post('https://www.funaitutor.co.uk/users/reset-password', { payload });
      setModalTitle("Success");
      setModalMessage("Password has been reset successfully.");
      setModalAction(() => () => navigate('/login'));
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
        navigate('/login');
      }, 3000);
      setTimeoutId(id);
    } catch (error) {
      console.error('Error resetting password', error);
      setModalTitle("Error");
      setModalMessage("Error resetting password. Please try again.");
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 2000);
      setTimeoutId(id);
    }
  };

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
    if (modalAction) {
      modalAction();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Typography variant="h6">
            Changing password for the account: {emailOrUsername}.
          </Typography>
          <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1em' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirm-password"
              label="Confirm New Password"
              name="confirm-password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: '1em 0' }}
            >
              Reset Password
            </Button>
          </form>
        </Box>
      </Container>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </React.Fragment>
  );
};

export default ChangePasswordPage;
