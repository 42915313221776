import React, { useState } from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #5c6bc0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: #2e40a4;
  }
`;

const CopyButton = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <StyledButton onClick={copyToClipboard}>
      {copied ? 'Copied' : 'Copy code'}
    </StyledButton>
  );
};

export default CopyButton;
