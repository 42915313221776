import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Box, Container, TextField, Button, Typography, CssBaseline, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import Modal from '../components/Modal';

const loginValidationSchema = yup.object().shape({
  login: yup.string().required('Email or Username is required'),
  password: yup.string().min(4, 'Password must be at least 4 characters').required('Password is required'),
});

const LoginPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const { login: authLogin } = useAuth();
  const { isDarkTheme, theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalAction, setModalAction] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const normalisedLogin = login.trim().toLowerCase();

      await loginValidationSchema.validate({ login: normalisedLogin, password });
      await authLogin(normalisedLogin, password);
      setModalTitle('Success');
      setModalMessage('Logged in successfully!');
      setModalAction(() => () => navigate('/chatbot'));
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
        navigate('/');
      }, 3000);
      setTimeoutId(id);
    } catch (error) {
      console.error('Login error:', error);
      setModalTitle('Error');
      setModalMessage(error.message);
      setModalAction(null);
      setModalShow(true);
      const id = setTimeout(() => {
        setModalShow(false);
      }, 3000);
      setTimeoutId(id);
    }
  };

  const handleCloseModal = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setModalShow(false);
    if (modalAction) {
      modalAction();
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: { xs: 8, sm: 10 },
        }}
      >
        <Box
          sx={{
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3,
            borderRadius: 1,
            boxShadow: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          {message && (
            <Typography
              variant="h6"
              align="center"
              color={theme.palette.text.primary}
              sx={{ mb: 2 }}
            >
              {message}
            </Typography>
          )}
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form onSubmit={handleLogin} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Email or Username"
              name="login"
              autoComplete="login"
              autoFocus
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              sx={textFieldStyle(isDarkTheme)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'} // Password visibility toggle
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={textFieldStyle(isDarkTheme)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Login
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.text.primary,
                  padding: '5px 10px',
                  borderRadius: '4px',
                }}
              >
                <Link to="/forgot-password" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                  Forgot Password?
                </Link>
              </Box>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.text.primary,
                  padding: '5px 10px',
                  borderRadius: '4px',
                }}
              >
                <Link to="/register" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                  Register
                </Link>
              </Box>
            </Box>
          </form>
        </Box>
      </Container>
      <Modal show={modalShow} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
    </React.Fragment>
  );
};

function textFieldStyle(isDarkTheme) {
  return {
    '& .MuiInputBase-input': { color: isDarkTheme ? 'white' : 'black' },
    '& label.Mui-focused': { color: isDarkTheme ? 'white' : 'black' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&:hover fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
      '&.Mui-focused fieldset': { borderColor: isDarkTheme ? 'white' : 'black' },
    },
  };
}

export default LoginPage;
