import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useAuth } from './AuthContext';
import CryptoJS from 'crypto-js';

function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
}

function decryptData(data) {
  try {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
}

export const ChatContext = createContext();

export const ChatProvider = ({ children, storedConversations }) => {
  const { user, handleSessionExpired, isVerifying } = useAuth();

  // Initialize state with an empty array until user is verified
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [currentConversationHistory, setCurrentConversationHistory] = useState([]);
  const [firstMessage, setFirstMessage] = useState(false);

  // Fetch conversations once the user is verified
  const fetchConversations = useCallback(async () => {
    if (isVerifying) {
      // Do nothing while verifying
      return;
    }
    if (!user) {
      console.error("No user logged in.");
      return;
    }
  
    try {
      const response = await fetch('https://www.funaitutor.co.uk/chatHistory/conversations', {
        method: 'GET',
        credentials: 'include'
      });
  
      if (!response.ok) {
        console.error(`Failed to fetch conversations. Status: ${response.status}`);
  
        // Check for authentication-related errors
        if ([401, 403, 400].includes(response.status)) {
          handleSessionExpired();
        }
        return;
      }
  
      const data = await response.json();
  
      // Check for error in response data before decryption
      if (data.error) {
        console.error('Server returned an error:', data.error);
        handleSessionExpired();
        return;
      }
  
      const decryptedData = decryptData(data.reply);
  
      if (!decryptedData) {
        console.error('Failed to decrypt data or data is null.');
        handleSessionExpired();
        return;
      }
  
      if (Array.isArray(decryptedData)) {
        setConversations(decryptedData);
      } else {
        console.error('Data is not an array:', decryptedData);
        handleSessionExpired(); // Data is invalid, treat as session expired
      }
    } catch (error) {
      console.error('Error fetching conversation history:', error);
      handleSessionExpired(); // Handle network or other errors
    }
  }, [user, isVerifying, handleSessionExpired]);
   

  // Handle changes in user authentication state
  useEffect(() => {
    if (isVerifying) {
      // Do nothing while verifying
      return;
    }
    if (user) {
      setCurrentConversationId(decryptData(localStorage.getItem('currentConversationId')) || null);
      setCurrentConversationHistory(decryptData(localStorage.getItem('currentConversationHistory')) || []);
      fetchConversations();
    } else if (!user) {
      setConversations([]);
      setActiveConversation(null);
      setCurrentConversationId(null);
      setCurrentConversationHistory([]);
      setFirstMessage(false);

      localStorage.removeItem('conversations');
      localStorage.removeItem('currentConversationId');
      localStorage.removeItem('currentConversationHistory');
    }
  }, [user, isVerifying, fetchConversations]);

  // Update localStorage whenever conversations change
  useEffect(() => {
    if (user) {
      localStorage.setItem('conversations', encryptData(conversations));
    }
  }, [conversations, user]);

  // Update localStorage whenever currentConversationId changes
  useEffect(() => {
    if (user) {
      localStorage.setItem('currentConversationId', encryptData(currentConversationId));
    }
  }, [currentConversationId, user]);

  // Update localStorage whenever currentConversationHistory changes
  useEffect(() => {
    if (user) {
      localStorage.setItem('currentConversationHistory', encryptData(currentConversationHistory));
    }
  }, [currentConversationHistory, user]);

  // Set active conversation based on the local storage when conversations change
  useEffect(() => {
    if (user) {
      const storedActiveConversationId = decryptData(localStorage.getItem('activeConversationId'));
      if (storedActiveConversationId) {
        const activeConv = conversations.find((conv) => conv._id === storedActiveConversationId);
        if (activeConv) {
          setActiveConversation(activeConv);
          setCurrentConversationId(storedActiveConversationId);
          setCurrentConversationHistory(activeConv.messages);
        }
      }
    }
  }, [conversations, user]);

  const addMessage = (newMessage, conversationId) => {
    if (!newMessage || typeof newMessage.content !== 'string' || !newMessage.content.trim()) {
      console.error('Input message is undefined, not a string, or empty.');
      return;
    }

    setConversations(prevConversations => {
      const updatedConversations = prevConversations.map(conversation => {
        if (conversation._id === conversationId) {
          const updatedMessages = [...conversation.messages, newMessage];
          return { ...conversation, messages: updatedMessages };
        }
        return conversation;
      });

      if (user) {
        localStorage.setItem('conversations', encryptData(updatedConversations));
      }

      if (conversationId === currentConversationId) {
        const updatedConversation = updatedConversations.find(c => c._id === conversationId);
        setCurrentConversationHistory(updatedConversation.messages);
      }

      return updatedConversations;
    });
  };

  const clearChat = async () => {
    if (!user) {
      console.error("User is not logged in.");
      return;
    }
  
    try {
      // Encrypt the payload
      const encryptedPayload = encryptData({ userId: user.id });
  
      const response = await fetch('https://www.funaitutor.co.uk/chatbot/startConversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payload: encryptedPayload }),
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`Failed to create a new conversation. Status: ${response.status}`);
      }
  
      const data = await response.json();
      const decryptedData = decryptData(data.reply); // Decrypting the response here
  
      const newConversation = {
        _id: decryptedData.conversationId,
        title: decryptedData.title,
        messages: []
      };
  
      setConversations((prevConversations) => {
        const updatedConversations = [...prevConversations, newConversation];
        if (user) {
          localStorage.setItem('conversations', encryptData(updatedConversations));
        }
        return updatedConversations;
      });
  
      setCurrentConversationId(decryptedData.conversationId);
      setActiveConversation(newConversation);
      setCurrentConversationHistory([]);
      setFirstMessage(true);

      // Store new conversation details in localStorage
      localStorage.setItem('currentConversationId', encryptData(decryptedData.conversationId));
      localStorage.setItem('activeConversationId', encryptData(decryptedData.conversationId));
      localStorage.setItem('currentConversationHistory', encryptData([]));
    } catch (error) {
      console.error("Failed to create a new conversation:", error);
    }
  };  

  const renameConversation = async (conversationId, newTitle) => {
    if (user) {
      setConversations((prevConversations) => {
        const updatedConversations = prevConversations.map((conversation) => {
          if (conversation._id === conversationId) {
            return { ...conversation, title: newTitle };
          }
          return conversation;
        });
        if (user) {
          localStorage.setItem('conversations', encryptData(updatedConversations));
        }
        return updatedConversations;
      });
  
      if (activeConversation && activeConversation._id === conversationId) {
        setActiveConversation((prevActiveConversation) => ({ ...prevActiveConversation, title: newTitle }));
      }
  
      try {
        // Encrypt the payload
        const encryptedPayload = encryptData({ title: newTitle });
  
        const response = await fetch(`https://www.funaitutor.co.uk/chatHistory/conversation/${conversationId}/updateTitle`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ payload: encryptedPayload }),
          credentials: 'include'
        });
        const data = await response.json();
        const decryptedData = decryptData(data.reply); // Decrypting the response here
  
        if (!decryptedData.success) {
          throw new Error('Failed to update title');
        }
  
        setConversations(prevConversations => {
          return prevConversations.map(conversation => {
            if (conversation._id === conversationId) {
              return { ...conversation, title: newTitle };
            }
            return conversation;
          });
        });
  
        if (activeConversation && activeConversation._id === conversationId) {
          setActiveConversation(prev => ({ ...prev, title: newTitle }));
        }
  
        localStorage.setItem('conversations', encryptData(conversations));
      } catch (error) {
        console.error('Error updating conversation title:', error);
      }
    }
  };  

  const deleteConversation = async (conversationId) => {
    if (user) {
      const isActive = activeConversation && activeConversation._id === conversationId;
      setConversations((prevConversations) =>
        prevConversations.filter((conversation) => conversation._id !== conversationId)
      );
  
      try {
        const response = await fetch(`https://www.funaitutor.co.uk/chatHistory/conversation/${conversationId}`, {
          method: 'DELETE',
          credentials: 'include'
        });
  
        if (!response.ok) throw new Error('Failed to delete conversation');
  
        const data = await response.json();
        const decryptedData = decryptData(data.reply); // Decrypting the response here
  
        if (!decryptedData.success) throw new Error('Failed to delete conversation');
  
        // If the deleted conversation was the active one, update the active conversation
        if (isActive) {
          const remainingConversations = conversations.filter(conv => conv._id !== conversationId);
          if (remainingConversations.length > 0) {
            const newActiveConversation = remainingConversations[0];
            setCurrentConversationId(newActiveConversation._id);
            setCurrentConversationHistory(newActiveConversation.messages);
            setActiveConversation(newActiveConversation);
            localStorage.setItem('activeConversationId', encryptData(newActiveConversation._id));
          } else {
            setCurrentConversationId(null);
            setCurrentConversationHistory([]);
            setActiveConversation(null);
            localStorage.removeItem('activeConversationId');
          }
        }
      } catch (error) {
        console.error('Error deleting conversation:', error);
      }
    }
  };  

  return (
    <ChatContext.Provider
      value={{
        conversations,
        setConversations,
        activeConversation,
        setActiveConversation,
        currentConversationId,
        setCurrentConversationId,
        currentConversationHistory,
        setCurrentConversationHistory,
        addMessage,
        clearChat,
        renameConversation,
        firstMessage,
        setFirstMessage,
        deleteConversation,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
