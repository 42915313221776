import React, { memo, useState, useEffect, useRef, useContext } from 'react';
import { Button, Paper, Avatar } from '@mui/material';
import styled from '@emotion/styled';
import { deepPurple, blue } from '@mui/material/colors';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { ChatContext } from '../context/ChatContext';
import CryptoJS from 'crypto-js';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { MdComputer } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import CopyButton from './CopyButton'; // Import the CopyButton component

const Thinking = () => (
  <div className='flex items-end mt-4'>
    <div className='avatar'>
      <div className='w-10 border rounded-full'>
        <MdComputer style={{ width: '40px', height: '40px', margin: 'auto' }} />
      </div>
    </div>
    <div className='chat chat-start'>
      <div className='chat-bubble animate-pulse'>thinking...</div>
    </div>
  </div>
);

// Styling constants
const theme = {
  borderRadius: '20px',
  userMessageBg: '#e2e2e2',
  botMessageBg: '#f0f0f0',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  hoverBoxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  inputShadow: '0 2px 4px rgba(0,0,0,0.2)',
  buttonBg: '#5c6bc0',
  buttonHoverBg: '#2e40a4',
  buttonHoverShadow: '0 4px 6px rgba(0,0,0,0.3)',
  transformHover: 'translateY(-2px)',
};

// Styled Components
const ChatContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxHeight: '100%',
  border: '1px solid #ccc',
  borderRadius: '8px',
  overflowY: 'auto',
  padding: '10px',
  position: 'relative',
  boxShadow: theme.boxShadow,
  '@media (max-width: 600px)': {
    padding: '5px',
  },
});

const ChatDisplay = styled.div({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '10px',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    padding: '5px',
  },
});

const MessageRow = styled.div(({ isUser }) => ({
  display: 'flex',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  alignItems: 'center',
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    marginBottom: '5px',
  },
}));

const MessageBubble = styled(Paper)(({ isUser, isDarkTheme }) => ({
  backgroundColor: isUser ? theme.userMessageBg : (isDarkTheme ? '#333' : theme.botMessageBg),
  color: isUser ? 'black' : (isDarkTheme ? 'white' : 'black'),
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  borderRadius: theme.borderRadius,
  padding: '12px 16px',
  maxWidth: '75%',
  boxShadow: theme.boxShadow,
  '&:hover': {
    boxShadow: theme.hoverBoxShadow,
    transform: theme.transformHover,
  },
  margin: isUser ? '0 0 0 10px' : '0 10px 0 0',
  '@media (max-width: 600px)': {
    padding: '8px 12px',
    maxWidth: '90%',
  },
}));

const FormContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  '@media (max-width: 600px)': {
    padding: '5px',
    gap: '5px',
  },
});

const StyledTextarea = styled.textarea(({ isDarkTheme }) => ({
  flex: 1,
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  boxShadow: isDarkTheme ? '0 2px 4px rgba(255, 255, 255, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.2)',
  backgroundColor: isDarkTheme ? '#333' : '#fff',
  color: isDarkTheme ? 'white' : 'black',
  resize: 'none',
  overflow: 'auto',
  minHeight: '60px',
  maxHeight: '120px',
  '@media (max-width: 600px)': {
    padding: '8px',
    minHeight: '50px',
  },
}));

const StyledButton = styled(Button)({
  backgroundColor: theme.buttonBg,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.buttonHoverBg,
    boxShadow: theme.buttonHoverShadow,
  },
  '@media (max-width: 600px)': {
    padding: '6px 12px',
  },
});

// Custom styled markdown components
const StyledH1 = styled.h1`
  font-size: 1.5em;
  color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'black')};
  margin-bottom: 0.5em;
`;

const StyledH2 = styled.h2`
  font-size: 1.2em;
  color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'black')};
  margin-bottom: 0.5em;
`;

const StyledBold = styled.strong`
  font-size: 1.1em;
  color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : 'black')};
  font-weight: bold;
  margin-bottom: 0.5em;
  display: inline;
`;

const H1 = memo(({ children }) => {
  const { isDarkTheme } = useTheme();
  return <StyledH1 isDarkTheme={isDarkTheme}>{children}</StyledH1>;
});

const H2 = memo(({ children }) => {
  const { isDarkTheme } = useTheme();
  return <StyledH2 isDarkTheme={isDarkTheme}>{children}</StyledH2>;
});

const Bold = memo(({ children }) => {
  const { isDarkTheme } = useTheme();
  return <StyledBold isDarkTheme={isDarkTheme}>{children}</StyledBold>;
});

// Code block components
const CodeBlockContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
  overflow: auto;
`;

const CodeBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: black;
  margin-bottom: 0.5rem;
`;

// Helper function to extract code string
const getCodeString = (children) => {
  if (Array.isArray(children)) {
    return children.map(child => {
      if (typeof child === 'string') {
        return child;
      } else if (typeof child === 'object' && child.props) {
        return child.props.children;
      } else {
        return '';
      }
    }).join('');
  } else {
    return children;
  }
};

// ReactMarkdown components customization
const components = {
  h1: H1,
  h2: H2,
  strong: Bold,
  a: ({ node, children, ...props }) => (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children.length === 0 ? <span aria-hidden="true">Link</span> : children}
    </a>
  ),
  code: ({ node, inline, className = '', children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    const language = match && match[1] ? match[1] : 'python'; // Default to python
    const codeString = getCodeString(children);

    return !inline ? (
      <CodeBlockContainer>
        <CodeBlockHeader>
          <span>{language}</span>
          <CopyButton code={codeString} />
        </CodeBlockHeader>
        <SyntaxHighlighter language={language} style={darcula}>
          {codeString}
        </SyntaxHighlighter>
      </CodeBlockContainer>
    ) : (
      <code {...props} className={className}>
        {codeString}
      </code>
    );
  },
};

  // New styled Avatar components to address length of username fitting in padding
  const getAvatarStyle = (usernameLength) => {
    let style = { fontSize: '1rem', padding: '0.5rem' }; // default style

    // Adjust font size and padding based on the username length
    if (usernameLength > 10) {
      style.fontSize = '0.50rem'; // smaller font size for longer names
      style.padding = '0.65rem'; // more padding to ensure text fits
    } else if (usernameLength > 6) {
      style.fontSize = '0.75rem';
      style.padding = '0.55rem';
    }

    return style;
  };

  // Adjust the Avatar styled component if necessary
  const CustomAvatar = styled(Avatar)(({ usernameLength }) => {
    const style = getAvatarStyle(usernameLength);
    return {
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: style.fontSize,
      padding: style.padding,
      // Other styles here
    };
  });

// Function to get avatar text
const getAvatarText = (sender, user) => {
  // If the sender is 'user', use the logged-in user's name or default to 'user'
  if (sender === 'user') {
    return user?.username || 'user';
  }
  // If the sender is anything else, it's the bot's message
  return 'FunAI';
};

const CenteredContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  textAlign: 'center',
});


const Chat = () => {
  const { addMessage, firstMessage, currentConversationId, currentConversationHistory, clearChat, setCurrentConversationHistory } = useContext(ChatContext);
  const { register, handleSubmit, reset } = useForm();
  const { user, isVerifying, handleSessionExpired } = useAuth();
  const { isDarkTheme } = useTheme();
  const [isThinking, setIsThinking] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Only scroll when user is verified and session is active
    if (!isVerifying && user) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (!user) {
      // If there's no user, handle session expiration
      handleSessionExpired();
    }
  }, [currentConversationHistory, handleSessionExpired, isVerifying, user]); // Add dependencies for isVerifying and user
  

  useEffect(() => {
    reset();
  }, [currentConversationId, reset]);

  const autoResizeTextarea = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
  };

  const onSubmit = async (data) => {
    const { userInput } = data;

    if (!user || !currentConversationId) {
      console.error("No user logged in or no active conversation.");
      setCurrentConversationHistory([{ 
        sender: 'system', 
        content: 'Just a moment, we need to start a new conversation for you!', 
        timestamp: new Date() 
      }]);

      setTimeout(() => {
        clearChat().then(() => {
          setCurrentConversationHistory([]);
        }).catch(error => {
          console.error("Error starting a new conversation:", error);
          setCurrentConversationHistory([{ 
            sender: 'system', 
            content: 'Failed to start a new conversation.', 
            timestamp: new Date() 
          }]);
        });
      }, 3000);
      return;
    }

    const trimmedInput = userInput.trim();
    if (!trimmedInput) {
      console.error('Cannot send empty messages.');
      reset();
      return;
    }

    const newUserMessage = {
      content: trimmedInput,
      sender: 'user',
      timestamp: new Date(),
    };

    addMessage(newUserMessage, currentConversationId);
    reset();
    setIsThinking(true);

    try {
      const encryptedPayload = CryptoJS.AES.encrypt(JSON.stringify({ content: trimmedInput, userId: user.id, conversationId: currentConversationId }), process.env.REACT_APP_SECRET_KEY).toString();

      const response = await fetch('https://www.funaitutor.co.uk/chatbot/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payload: encryptedPayload }),
        credentials: 'include'
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send message');
      }

      const decryptedResponse = JSON.parse(CryptoJS.AES.decrypt(data.reply, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8));

      if (decryptedResponse.reply) {
        const botReply = {
          content: decryptedResponse.reply,
          sender: 'assistant',
          timestamp: new Date(),
        };
        addMessage(botReply, currentConversationId);
      }
    } catch (error) {
      console.error('Error sending message to chatbot:', error);
    } finally {
      setIsThinking(false);
    }
  };

  return (
    <ChatContainer>
      <ChatDisplay>
        {currentConversationHistory.length > 0 ? (
          currentConversationHistory.map((message, index) => (
            <MessageRow key={index} isUser={message.sender === 'user'}>
              <CustomAvatar
                sx={{ bgcolor: message.sender === 'user' ? deepPurple[500] : blue[500] }}
                usernameLength={getAvatarText(message.sender, user).length}
              >
                {getAvatarText(message.sender, user)}
              </CustomAvatar>
              <MessageBubble isUser={message.sender === 'user'} isDarkTheme={isDarkTheme}>
                <ReactMarkdown
                  components={components}
                  remarkPlugins={[remarkGfm]}
                >
                  {message.content}
                </ReactMarkdown>
              </MessageBubble>
            </MessageRow>
          ))
        ) : firstMessage ? (
          <CenteredContainer>
          <div>Start a new conversation by sending a message.</div>
          </CenteredContainer>
        ) : (
          <CenteredContainer>
          <div>No messages in this conversation yet.</div>
          </CenteredContainer>
        )}
        {isThinking && <Thinking />}
        <div ref={messagesEndRef} />
      </ChatDisplay>
      <FormContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <StyledTextarea
          {...register('userInput')}
          placeholder="Type your message here..."
          isDarkTheme={isDarkTheme}
          onInput={autoResizeTextarea}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }
          }}
        />
        <StyledButton type="submit" variant="contained">
          Send
        </StyledButton>
      </FormContainer>
    </ChatContainer>
  );
};

export default Chat;
